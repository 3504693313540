<template>
  <SideBar @toggle="$emit('toggle')" icon="bars">
    <div class="has-text-grey-dark has-text-weight-semibold">
      <router-link to="/guru/" class="dropdown-item">
        <font-awesome-icon icon="university"/>&nbsp;&nbsp;&nbsp;Courses And Assignments
      </router-link>
      <router-link to="/compiler-api/" class="dropdown-item has-text-weight-semibold">
        <font-awesome-icon icon="laptop-code"/>&nbsp;&nbsp;Compiler API
      </router-link>
      <router-link to="/compiler-api/" class="dropdown-item has-text-weight-semibold">
        <font-awesome-icon icon="th"/>&nbsp;&nbsp;IDE Plugin
      </router-link>
      <a href="https://docs.jdoodle.com" target="_blank" class="dropdown-item has-text-weight-semibold">
        <font-awesome-icon icon="book"/>&nbsp;&nbsp;Documents & FAQ
      </a>
      <div class="has-text-centered dropdown-item has-text-weight-semibold">
        <font-awesome-icon icon="code"/>&nbsp;&nbsp;IDEs & Terminals
      </div>
      <IdeLinksBox class="has-background-white-bis link-box"/>
    </div>
  </SideBar>
</template>

<script>
import SideBar from './Utils/SideBar'
import IdeLinksBox from './Utils/IDELinksBox'
export default {
  name: 'mainMenu',
  components: { IdeLinksBox, SideBar }
}
</script>

<style scoped lang="scss">
  .link-box {
    margin-top: 1em;
    padding-right: 5px;
  }
</style>
