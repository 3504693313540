<template>
  <div>
    <div>
      <div class="columns ">
        <div class="column is-12">
          <div class="field ide-search-field">
            <div class="control has-icons-left is-expanded">
              <input
                class="input is-rounded"
                type="text"
                placeholder="Search IDE/Compiler/Terminal"
                v-model="searchLanguage"
                v-on:keyUp="searchLinks"
              />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="search" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!searchLanguage"
        class="columns is-multiline is-mobile ide-link-list"
      >
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop lang-text"><router-link  to="/online-java-compiler/">Java</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop lang-text"><router-link  to="/online-java-compiler-ide/">Java (Advanced)</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/c-online-compiler/">C</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/online-compiler-c++/">C++</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/online-compiler-c++14/">C++ 14</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/online-compiler-c++17/">C++ 17</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-c99-online/">C99</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-c-sharp-online/">C#</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/php-online-editor/">PHP</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-perl-online/">Perl</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-ruby-online/">Ruby</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/python-programming-online/">Python2</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/python3-programming-online/">Python3</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-sql-online/">SQL</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-scala-online/">Scala</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-vb-dot-net-online/">VB.Net</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-pascal-online/">Pascal</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-haskell-online/">Haskell</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-kotlin-online/">Kotlin</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-swift-online/">Swift</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-objective-c-online/">Objective-C</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-groovy-online/">Groovy</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-fortran-online/">Fortran</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-brainfuck-online/">Brainf**k</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-hack-online/">Hack</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-tcl-online/">TCL</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-lua-online/">Lua</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-rust-online/">Rust</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-fsharp-online/">F#</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-ada-online/">Ada</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-d-online/">D</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-dart-online/">Dart</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-yabasic-online/">YaBasic</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-freebasic-online/">Free Basic</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-clojure-online/">Clojure</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-verilog-online/">Verilog</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-nodejs-online/">NodeJS</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-scheme-online/">Scheme</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-forth-online/">Forth</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-prolog-online/">Prolog</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/test-bash-shell-script-online/">Bash</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-cobol-online/">COBOL</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-octave-matlab-online/">OCTAVE/ Matlab</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-icon-online/">Icon</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-coffeescript-online/">CoffeeScript</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-assembler-gcc-online/">Assembler (GCC)</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-r-online/">R</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-assembler-nasm-online/">Assembler (NASM)</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-intercal-online/">Intercal</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-nemerle-online/">Nemerle</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-ocaml-online/">Ocaml</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-unlambda-online/">Unlambda</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-picolisp-online/">Picolisp</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-clisp-online/">CLISP</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-elixir-online/">Elixir</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-spidermonkey-online/">SpiderMonkey</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-rhino-online/">Rhino JS</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-bc-online/">BC</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-nim-online/">Nim</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-factor-online/">Factor</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-falcon-online/">Falcon</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-fantom-online/">Fantom</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-pike-online/">Pike</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-go-online/">Go</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/compile-oz-mozart-online/">OZ-Mozart</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-lolcode-online/">LOLCODE</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-racket-online/">Racket</router-link></div> -->
        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-smalltalk-online/">SmallTalk</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-whitespace-online/">Whitespace</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-erlang-online/">Erlang</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-jlanguage-online/">J Lang</router-link></div> -->

        <!-- <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-fasm-online/">Assembler (FASM)</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-awk-online/">AWK</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-algol68-online/">Algol 68</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-befunge-online/">Befunge</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/execute-haxe-online/">Haxe</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/try-jbang/">J Bang</router-link></div>

        <div class="column is-4-mobile is-4-tablet is-4-desktop lang-text"><router-link  to="/html-css-javascript-online-editor/">HTML & Javascript</router-link></div>
        <div class="column is-4-mobile is-4-tablet is-4-desktop  lang-text"><router-link  to="/blockly-online/">Blockly</router-link></div> -->

        <div class="is-clearfix" />
        <div class="column is-12 lang-text terminal-title has-text-centered">
          <span class="is-size-6 has-text-weight-semibold"
            >Online Interactive Terminals</span
          >
        </div>
        <!-- <div class="column is-6 lang-text has-text-centered"><router-link  to="/online-mysql-terminal/">MySQL</router-link></div>
        <div class="column is-6 lang-text has-text-centered"><router-link  to="/online-mongodb-terminal/">MongoDB</router-link></div> -->

        <div class="column is-12 lang-text terminal-title has-text-centered">
          <span class="is-size-6 has-text-weight-semibold"
            >Our other Products/Websites</span
          >
        </div>
        <div class="column is-12 lang-text has-text-centered">
          <a href="https://calc.jdoodle.com" target="_blank" rel="nofollow"
            >Online Interactive Calculator</a
          >
        </div>
      </div>
      <div
        v-show="searchLanguage"
        class="columns is-multiline has-text-centered"
      >
        <div
          class="column is-12 lang-text"
          v-for="link in links"
          :key="link.language"
        >
          <router-link :to="link.link">{{ link.displayName }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LANGS_AND_LINKS } from '../../assets/javascript/languages-and-links'

export default {
  name: 'ideLinksBox',
  data: function () {
    return {
      searchLanguage: null
    }
  },
  methods: {
    searchLinks: function () {}
  },
  computed: {
    links: function () {
      if (!this.searchLanguage) {
        return []
      }

      let links = this.$_.filter(LANGS_AND_LINKS, o => {
        return o.searchTerm.includes(this.searchLanguage.toLowerCase())
      })

      return links
    }
  }
}
</script>

<style scoped lang="scss">
.ide-search-field {
  margin: 5px 8px;
}

.lang-text {
  font-size: 0.8em;
  padding: 0.1em;
}

.lang-text > a {
  color: #4a4a4a;
  font-weight: bold;
}

.ide-link-list {
  padding: 1em;
  margin-left: 0.5em;
  font-weight: 500;
}

.terminal-title {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
